<template>

	<div class="cont" id="p08">
		<div class="page-title">{{ program.name }}</div>

		<div class="c01">
			<div class="s01">
				<div class="s-item">
					<div class="s-header">
						<div class="s-title">결제정보</div>
						
					</div>
					<div class="s-body">
						<div class="s-content">
							<div class="si-title">결제일시</div>
							<div class="si-desc text-right">{{ item.requestDateTime }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">결제상태</div>
							<div class="si-desc text-right"
								:class="{ 'txt-success': item.status == 'success', 'txt-warning': item.status == 'request', 'txt-error': item.status != 'success' && item.status != 'request' }"
							>{{ item.statusName }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">상품명</div>
							<div class="si-desc text-right">{{ item.goodsName }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">판매금액</div>
							<div class="si-desc text-right">{{ item.paymentAmount | makeComma }}원</div>
						</div>
					</div>
				</div>
				<div class="s-item" style="padding-bottom: 0;">
					<div class="s-header">
						<div class="s-title">주문자 정보</div>
					</div>
					<div class="s-body">
						<div class="s-content">
							<div class="si-title">주문자명</div>
							<div class="si-desc text-right">{{ item.buyerName }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">연락처</div>
							<div class="si-desc text-right">{{ item.buyerContact | makeTell }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">메모</div>
							<div class="si-desc">{{ item.memo }}</div>
						</div>
					</div>
				</div>
				<div class="s-item" style="padding-bottom: 0;">
					<div class="s-header">
						<div class="s-title">영업점</div>
					</div>
					<div class="s-body">
						<div class="s-content">
							<div class="si-title">영업담당자</div>
							<div class="si-desc">{{ item.salesName }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">연락처</div>
							<div class="si-desc">{{ item.salesContact }}</div>
						</div>
					</div>
				</div>
			</div>

			<div class="s03">
				<a v-if="isCancel"><div
					@click="cancel" 
					class="btn-cancel mr-10"
					>결제 취소</div></a>
				<a v-if="isSuccess"><div 
					@click="viewBill" 
					class="btn-save mr-10"
					:disabled="false"
					>영수증</div></a>
				<a ><div 
					@click="$router.go(-1)"
					class="btn-back"
					>목록</div></a>
			</div>
		</div>
		
		<PaymentsPopupBill 
			v-if="view.bill"
			:item="item"
			
			@setOverlay="setOverlay"
			@setNotify="setNotify"
		/>
		
		</div>
</template>

<script>

	import { Axios } from '@/resources/axios/axios'
	import { rules } from '@/resources/rules/rules'
	import { filter } from '@/resources/filter/filter'
	
	import PaymentsPopupBill from '@/view/Payments/PaymentsPopupBill'
	
	export default{
		name: "PaymentsHistoryDetail"
		,props: ['user', 'codes']
		,components: { PaymentsPopupBill }
		,data: function(){
			return {
				program: {
					name: this.$language.menu[3].list[3].name
				}
				,requestId: this.$route.params.requestId
				,requestKey: this.$route.params.requestKey
				,item: {
					basic: {}
					,manger: {}
					,company: {}
					,settlement: {}
					,paymentItems: {}
				}
				,view: {
					bill: false
				}
				,rules:rules
			}
		}
		,filter: filter
		,computed: {
			isCancel: function(){
				if(this.item.status == 'request'){
					return true
				}else{
					return false
				}
			}
			,isSuccess: function(){

				if(this.item.status == 'success'){
					return true
				}else{
					return false
				}
			}
			,statusName: function(){
			
				if(this.item.tranStatus){
					let index = this.codes.transStats.map(function(o){ return o.code; }).indexOf(this.item.tranStatus)
					return this.codes.transStats[index].codeName
				}else{
					return ''
				}
				
			}
		}
		,methods: {
			getData: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/sms/detail/' + this.requestId + '/' + this.requestKey
						,authorize: true
					})
					
					if(result.success){
						this.item = result.data.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(E){
					console.log(E)
					this.$emit('setNotify', { type: 'error', message: E.status})
				}
			}
			,cancel: async function(){
				if(confirm(this.$language.common.askCancel)){
					try{
						const result = await Axios({
							method: 'post'
							,url: '/sms/' + this.requestId + '/' + this.requestKey + '/cancel'
							,authorize: true
						})
						
						if(result.success){
							this.$emit('setNotify', { type: 'success', message: result.data.message })
							this.getData()
						}else{
							this.$emit('setNotify', { type: 'error', message: result.message })
						}
					}catch(E){
						console.log(E)
						this.$emit('setNotify', { type: 'error', message: E.status})
					}
				}
			}
			,viewBill: function(){
				this.view.bill = true
				this.$emit('setOverlay', true)
			}
			,setOverlay: function(type){
				this.$emit('setOverlay', type)
				if(!type){
					this.view.bill = false
				}
			}
			,setNotify: function(option){
				this.$emit('setNotify', option)
			}
		}
		,created: function(){
			this.getData()
		}
		,watch: {
			managerMobilPhoneNumber: {
				deep: true
				,handler: function(call){
					this.item.manger.managerMobilPhoneNumber = call.fir + call.sec + call.thi
				}
			}
		}
	}
</script>

<style>
	#p08 .si-desc { text-align: right !important;}
</style>





















